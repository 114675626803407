import { inject, observer } from "mobx-react";
import { Fragment, useMemo } from "react";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { Grid, Heading } from "../../ui";
import { HeadingProps } from "../../ui/Heading.component";
import { AdPosition } from "../Ad.component";
import { CalendarDatasourceMenu } from "./CalendarDatasourceMenu.component";
import { DatasourceProps } from "./Datasource.component";
import "./Datasource.style.scss";
import { NoCalendarFeedItemMessage } from "./NoCalendarFeedItemMessage.component";
import { getEventStartDate } from "../../lib/calendar";
import { getLayout } from "../../lib/content";
import loadable from "@loadable/component";
import { blockHaveAd, isFeedResult, isCalendarPublishRecord, showLoadMoreAd } from "@inferno/renderer-shared-core";

const ContentFeedItem = loadable(() => import("./ContentFeedItem.component"));

export const CalendarDatasource = inject("store")(
  observer((props: DatasourceProps) => {
    const { store, title, content, feedId, displayHints, block } = props;

    if (!store) {
      return null;
    }

    const heading: HeadingProps = useMemo(() => props.heading ?? { level: 2 }, [props.heading]);
    const adBlockData = useMemo(() => ({ value: { ...block.value } } as BlockFragment), [block.value]);
    const { showEyebrow, enableLoadMore, showDateStamp } = displayHints;
    const adInsertPosition = useMemo(() => block.value.size || 10, [block.value.size]);
    const haveAds = useMemo(() => blockHaveAd(block.value.position), [block.value.position]);

    const header = useMemo(() => (title ? <Heading {...heading}>{title}</Heading> : null), [title, heading]);
    const showMenu = props.displayHints.hints?.includes("display-hints/calendar-show-menu");

    const items = useMemo(
      () =>
        content?.map((item, i) => {
          const { style } = displayHints;

          if (!isFeedResult(item) || !isCalendarPublishRecord(item.record)) {
            return null;
          }

          const showAd = showLoadMoreAd(content.length, adInsertPosition, i, enableLoadMore);
          return (
            <Fragment key={`feed-item-${feedId}-${(item as any).ref_id}-${i}`}>
              <ContentFeedItem
                item={item.record}
                index={i}
                showEyebrow={showEyebrow}
                showDateStamp={showDateStamp}
                datetime={getEventStartDate(item.record.payload.fields) ?? item.record.pub_start}
                layout={getLayout(style)}
              />
              {haveAds && showAd ? (
                <div className="load-more-ad">
                  <AdPosition block={adBlockData} batchId={`${feedId}-${Math.ceil(i / adInsertPosition)}`} />
                </div>
              ) : null}
            </Fragment>
          );
        }) ?? [],
      [
        adBlockData,
        adInsertPosition,
        content,
        displayHints,
        enableLoadMore,
        feedId,
        haveAds,
        showEyebrow,
        showDateStamp,
      ],
    );

    const isNoEvents = items.every(item => item === null);

    return (
      <Fragment key={block.id}>
        {header}
        {showMenu ? <CalendarDatasourceMenu store={store} /> : null}
        <div className="feed-cards" data-block-id={block.id} suppressHydrationWarning={true}>
          {!isNoEvents ? <Grid columns={displayHints.columns}>{items}</Grid> : <NoCalendarFeedItemMessage />}
        </div>
      </Fragment>
    );
  }),
);

export default CalendarDatasource;
